// External
import React from 'react';
import { useDispatch } from 'react-redux';
import { string, object } from 'yup';
import { Formik } from 'formik';
import FormBoot from 'react-bootstrap/Form';

// Internal
import { Button, Modal } from 'app/components';
import { handleSubmitPasswordChange } from 'app/state/auth/actions';

import css from './Login.module.scss';

const schema = object({
  email: string()
    .required('E-mail é obrigatório')
    .email('E-mail inválido'),
});

type FormValues = {
  email: string,
};

function ForgotPassword({ show, onHide }): JSX.Element {
  const dispatch = useDispatch();
  return (
    <div className={css['login-modal']}>
      <Modal
        isVisible={show}
        onClose={onHide}
      >
        <Modal.Title>
          Recuperar senha
        </Modal.Title>
        <Modal.Body>
          <Formik
            onSubmit={({ email }: FormValues) => dispatch(handleSubmitPasswordChange(email))}
            validationSchema={schema}
            initialValues={{
              email: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
            }) => (
              <FormBoot noValidate>
                <FormBoot.Group controlId="email">
                  <FormBoot.Label>E-mail</FormBoot.Label>
                  <FormBoot.Control
                    type="email"
                    placeholder="Informe seu e-mail..."
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />

                  <FormBoot.Control.Feedback type="invalid">
                    {errors.email}
                  </FormBoot.Control.Feedback>
                </FormBoot.Group>
                <Button
                  onClick={handleSubmit}
                  kind="warning"
                  type="submit"
                  block
                >
                  Enviar
                </Button>
              </FormBoot>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}


export default ForgotPassword;
