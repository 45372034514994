import * as React from 'react';

interface Props {
  source: string;
  alt: string;
  fallback: {
    src: string;
    type: string;
  },
  className?: string;
}

function WebPicture({
  source, alt, fallback, className = '',
} : Props) : JSX.Element {
  return (
    <picture>
      <source srcSet={source} type="image/webp" />
      <source srcSet={fallback.src} type={fallback.type} />
      <img src={fallback.src} alt={alt} className={className} />
    </picture>
  );
}

WebPicture.defaultProps = {
  className: '',
};

export default WebPicture;
