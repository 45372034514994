// External
import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { string, object } from 'yup';
import { Formik } from 'formik';
import FormBoot from 'react-bootstrap/Form';

// Internal
import {
  Button,
  Modal,
  Message,
  Text,
} from 'app/components';
import css from './Login.module.scss';
import { resendVerification } from '../../../state/auth/actions';

const schema = object({
  email: string()
    .required('E-mail é obrigatório')
    .email('E-mail inválido'),
  password: string().required('Senha é obrigatório'),
});

const Login = function ({
  show,
  onHide,
  toogleModalPassword,
  onPressLogin,
}) {
  const pendingConfirmation = useSelector((state) => state.auth.pendingConfirmation, shallowEqual);
  const dispatch = useDispatch();
  return (
    <div className={css['login-modal']}>
      <Modal isVisible={show} onClose={onHide}>
        <Modal.Title>Entrar</Modal.Title>
        <Modal.Body>
          <Formik
            onSubmit={(values) => onPressLogin(values)}
            validationSchema={schema}
            initialValues={{
              email: '',
              password: '',
            }}
          >
            {({
              handleSubmit, handleChange, values, errors, touched,
            }) => (
              <FormBoot noValidate>
                <FormBoot.Group controlId="email">
                  <FormBoot.Label>E-mail</FormBoot.Label>
                  <FormBoot.Control
                    type="email"
                    placeholder="Entre com seu e-mail..."
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email && touched.email}
                  />

                  <FormBoot.Control.Feedback type="invalid">{errors.email}</FormBoot.Control.Feedback>
                </FormBoot.Group>
                <FormBoot.Group controlId="password">
                  <FormBoot.Label>Senha</FormBoot.Label>
                  <FormBoot.Control
                    type="password"
                    placeholder="Entre com sua senha..."
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password && touched.password}
                  />
                  <FormBoot.Control.Feedback type="invalid">
                    {errors.password}
                  </FormBoot.Control.Feedback>
                </FormBoot.Group>

                {pendingConfirmation && (
                  <Message kind="error">
                    <Text padding="p-bottom" kind="error">
                      Seu e-mail não foi verificado. Acesse seu e-mail e clique no link para
                      confirmar.
                      <br />
                      Caso não tenha recebido,
                      {' '}
                      <button
                        type="button"
                        className={css['button-link-inline']}
                        onClick={() => dispatch(resendVerification(values.email))}
                      >
                        clique aqui
                      </button>
                      {' '}
                      para reenviar o e-mail de ativação.
                    </Text>
                  </Message>
                )}

                <div className={css['container-button-forgot']}>
                  <button
                    type="button"
                    className={css['button-link']}
                    onClick={toogleModalPassword.bind(this)}
                  >
                    Esqueci minha senha
                  </button>
                </div>
                <Button
                  block
                  type="submit"
                  kind="warning"
                  onClick={handleSubmit}
                >
                  Entrar
                </Button>
              </FormBoot>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};


export default Login;
