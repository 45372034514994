import React from 'react';

export const termsText = (
  <>
    Consulte as atualizações do
    <strong>&nbsp;Termo de uso&nbsp;</strong>
    e confirme que você está em conformidade.
  </>
);

export const policyText = (
  <>
    Consulte as atualizações da
    <strong>&nbsp;Política de Privacidade&nbsp;</strong>
    e confirme que você está em conformidade.
  </>
);

export const TERMS_URL =
  'https://suporte.iclinic.com.br/termos-e-condicoes-de-uso-do-agendarconsulta';
export const POLICY_URL =
  'https://suporte.iclinic.com.br/politica-de-privacidade-do-agendarconsulta';
export const INFO_REQUEST = '/pedidodotitular';
