// External
import * as React from 'react';
import Head from 'next/head';
import NavbarBoot from 'react-bootstrap/Navbar';
import NavBoot from 'react-bootstrap/Nav';
import RowBoot from 'react-bootstrap/Row';
import ColBoot from 'react-bootstrap/Col';
import ContainerBoot from 'react-bootstrap/Container';

// Internal
import Login from 'app/features/home/components/Login';
import { REGISTER } from 'app/shared/constants/screens';
import ForgotPassword from 'app/features/home/components/ForgotPassword';
import {
  TERMS_URL,
  POLICY_URL,
  INFO_REQUEST,
} from 'app/shared/constants/serviceTerms';
import WebPicture from 'components/WebPicture';

import css from './LayoutBootstrap.module.scss';

class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);
    this.state = {
      modalShow: false,
      modalShowPassword: false,
      year: new Date().getFullYear(),
    };
  }

  goToProfile = () => {
    window.location.href = '/criar-perfil-doutor';
  };

  toogleModalEnter = () => {
    this.setState({ modalShow: true });
  };

  toogleModalPassword = () => {
    this.setState({
      modalShow: false,
      modalShowPassword: true,
    });
  };

  modalClose = () =>
    this.setState({
      modalShow: false,
    });

  modalClosePassword = () =>
    this.setState({
      modalShowPassword: false,
      modalShow: true,
    });

  render() {
    const { modalShow, modalShowPassword, year } = this.state;
    const { scrollTo, children, screen, login } = this.props;
    return (
      <div className={css.layout}>
        <Head>
          <title>
            AgendarConsulta.com - Busque seu médico, agende uma consulta
          </title>
          <meta
            name="description"
            content="Acesse e encontre profissionais de saúde próximos de você. Escolha o melhor horário e agende uma consulta agora."
          />
        </Head>
        <div className={css['navbar-fixed']}>
          <ContainerBoot>
            <NavbarBoot className={css['navbar-custom']} expand="lg">
              <NavbarBoot.Brand className={css['logotipo-custom']} href="/">
                <WebPicture
                  source="/static/images/Logo_AgendarConsulta.webp"
                  fallback={{
                    type: 'image/png',
                    src: '/static/images/Logo_AgendarConsulta.png',
                  }}
                  alt="Agendar consulta"
                  className={css['logo-header']}
                />
              </NavbarBoot.Brand>
              <NavbarBoot.Toggle
                className={css.toggle}
                aria-controls="basic-navbar-nav"
              />
              <NavbarBoot.Collapse id="basic-navbar-nav">
                <NavBoot className="mr-auto">
                  {screen === REGISTER && (
                    <NavBoot.Link className={css.link} onClick={scrollTo}>
                      Como funciona
                    </NavBoot.Link>
                  )}
                </NavBoot>
                <NavBoot className={`mr-auto ${css.hide}`}>
                  <NavBoot.Link
                    className={css.link}
                    onClick={this.toogleModalEnter}
                  >
                    Entrar
                  </NavBoot.Link>
                </NavBoot>
                {screen !== REGISTER && (
                  <NavBoot className={`mr-auto ${css.hide}`}>
                    <NavBoot.Link
                      className={css.link}
                      href="/criar-perfil-doutor"
                    >
                      Criar Perfil
                    </NavBoot.Link>
                  </NavBoot>
                )}
                <div className={css['button-container']}>
                  <button type="button" onClick={this.toogleModalEnter}>
                    Entrar
                  </button>
                  {screen !== REGISTER && (
                    <button
                      type="button"
                      className={css.warning}
                      onClick={this.goToProfile}
                    >
                      Criar perfil médico
                    </button>
                  )}
                </div>
              </NavbarBoot.Collapse>
            </NavbarBoot>
          </ContainerBoot>
        </div>

        <div className={css.body}>{children}</div>

        <ContainerBoot>
          <footer>
            <RowBoot className={css.footer}>
              <ColBoot lg={7} md={12} sm={12}>
                <RowBoot className={css.links}>
                  <img
                    className={css.logo}
                    alt="Logo"
                    src="/static/images/logo_small.png"
                  />
                  {screen === REGISTER && (
                    <a
                      className={css['hide-on-md']}
                      href="#como-funciona"
                      onClick={scrollTo}
                    >
                      Como funciona
                    </a>
                  )}
                  <a href={POLICY_URL} target="_blank" rel="noreferrer">
                    Política de privacidade
                  </a>
                  <a href={TERMS_URL} target="_blank" rel="noreferrer">
                    Termos de uso
                  </a>
                  <a href={INFO_REQUEST} rel="nofollow">
                    Pedido do titular
                  </a>
                </RowBoot>
              </ColBoot>
              <ColBoot lg={5} md={12} sm={12} className={css.right}>
                {`${year} Ⓒ iClinic - Todos os direitos reservados`}
              </ColBoot>
            </RowBoot>
          </footer>
        </ContainerBoot>
        <Login
          show={modalShow}
          onHide={() => this.modalClose()}
          toogleModalPassword={() => this.toogleModalPassword()}
          onPressLogin={login}
        />

        <ForgotPassword
          show={modalShowPassword}
          onHide={() => this.modalClosePassword()}
        />
      </div>
    );
  }
}

Layout.defaultProps = {
  scrollTo: () => null,
  login: () => null,
  children: {},
  screen: '',
};

type LayoutState = {
  year: Number;
  modalShow: boolean;
  modalShowPassword: boolean;
};

type LayoutProps = {
  scrollTo: (e: {}) => void;
  login: (e: {}) => void;
  children?: React.ReactNode;
  screen: String;
};

export default Layout;
